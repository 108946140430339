<template>
  <div id="app">
    <Mazda/>
  </div>
</template>

<script>
import Mazda from './components/Mazda.vue'

export default {
  name: 'App',
  components: {
    Mazda
  }
}
</script>

<style lang="scss">
  @import 'scss/style.scss';
</style>
