<template>
  <div class="wrapper">
    <!-- header -->
    <header class="b-header">
      <div class="b-header__container container">
        <div class="b-header__logo">
          <img src="/img/logo.svg" alt="">
        </div>
        <button class="b-header__hamburger hamburger hamburger--spin" @click="openMenu" v-bind:class="{open}">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </button>
      </div>
    </header>
    <!--  -->

    <div class="b-content d-flex" v-if="loading">Loading...</div>

    <div class="b-content d-flex" v-if="!loading">
      <!-- error block -->
      <div class="w-100 d-flex align-items-center justify-content-center" v-if="error">
        <div class="b-error px-3">
          <div class="b-error__title">Внутрішня помилка сервера</div>
          <div class="b-error__img">
            <img src="/img/error.svg" alt="">
          </div>
        </div>
      </div>
      <!--  -->

      <div class="w-100 d-flex justify-content-center" v-if="!error">
        <div class="b-select container">
          <div class="b-select__nav">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-for="year in years" :key="year" v-model="filterYear" @change="filterYearChange" :aria-describedby="ariaDescribedby" name="some-radios" :value="year">{{year}}</b-form-radio>
            </b-form-group>
          </div>
          <div class="b-select__content">
            <div class="b-select__text-wrap">
              <span class="b-select__text">Вищий орган управління, до повноважень якого належить призначення суб’єкта аудиторської діяльності, який проводитиме аудит фінансової звітності, та його відсторонення, а також призначення аудиторського комітету є засновник, ІТОЧУ КОРПОРЕЙШН (Японія).</span>
              <span class="b-select__text">Згідно рішення засновника ІТОЧУ КОРПОРЕЙШН від 19.09.2022, <b>Товариство з обмеженою відповідальністю «Ернст енд Янг Аудиторські послуги»</b> призначено діловим партнером для виконання завдань з щорічних перевірок (аудитів) фінансової звітності ДП «АВТО Інтернешнл».</span>
              <span class="b-select__text">Згідно рішення засновника ІТОЧУ КОРПОРЕЙШН від 01.04.2020, призначений наступний склад аудиторського комітету ДП «АВТО ІНТЕРНЕШНЛ»:</span>
              <span class="b-select__text">Голова комітету – Чікако Терайяма – Менеджер Автомобільної секції № 4 Департаменту Автомобільного та Мобільного Бізнесу № 2 ІТОЧУ КОРПОРЕЙШН</span>
              <span class="b-select__text">Член комітету - Гринчишин Володимир Анатолійович – колишній керівник юридичної служби підприємства, період роботи в компанії: 1994 – листопад 2023, арбітр міжнародного комерційного арбітражного суду при Торгово-промисловій палаті України, кандидат юридичних наук, доцент</span>
              <span class="b-select__text">Член комітету - Нагула Наталія Миколаївна – Менеджер з бухгалтерського обліку та звітності, працює в компанії з 2012 року</span>
            </div>
            <div class="b-select__content-title">Звітність</div>
            <div class="b-select__grid">
              <div class="b-card" v-for="file in displayFiles" :key="file">
                <div class="b-card__type">PDF</div>
                <div class="b-card__title">{{ file.Title }}</div>
                <span class="b-card__text">{{ file.Desc }}</span>
                <a :href="file.downloadlink" class="b-card__button">
                  Завантажити
                  <img class="b-card__button-img" src="/img/download.svg" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
<!--        <b-card-group columns>-->
<!--          <b-card-->
<!--            v-for="file in displayFiles"-->
<!--            img-src="/PDF_file_icon.svg"-->
<!--            img-alt="Image"-->
<!--            img-top-->
<!--            :title="file.Title"-->
<!--            :key="file.Filename"-->
<!--            style="max-width: 300px"-->
<!--          >-->
<!--            <b-card-text>-->
<!--              {{ file.Desc }}-->
<!--            </b-card-text>-->
<!--            <template #footer>-->
<!--              <small class="text-muted">-->
<!--                {{ file.Year }}<br />-->
<!--                <a :href="file.downloadlink">Завантажити</a>-->
<!--              </small>-->
<!--            </template>-->
<!--          </b-card>-->
<!--        </b-card-group>-->
      </div>
    </div>

    <!-- footer -->
    <footer class="b-footer">
      <div class="container">
        <div class="row">
          <div class="col-4 col-md-3">
            <div class="b-footer__logo">
              <img src="/img/logo.svg" alt="">
            </div>
          </div>

          <div class="col-12 col-md-6 order-2 order-md-1 mt-3 mt-md-0">
            <div class="b-footer__discleimer">
              <!-- <span class="b-footer__discleimer-title">ДИСКЛЕЙМЕР:</span> -->
              <span class="b-footer__discleimer-text">Усі права зберігаються за правовласником.</span>
              <span class="b-footer__copyright">2021. ДП «АВТО Інтернешнл»</span>
            </div>
          </div>

          <div class="col-8 col-md-3 order-md-2">
            <div class="b-footer__contact">
              <div class="b-footer-phone">
                <div class="b-footer-phone__img">
                  <img src="/img/phone.svg" alt="">
                </div>
                <div class="b-footer-phone__text">
                  <div class=b-footer-phone__text-number>+38 044 230-15-67</div>
                  <div class=b-footer-phone__text-number>+38 050 316-40-06</div>
                </div>
              </div>
              <span class="b-footer__contact-text">Україна,  04073, м. Київ,</span>
              <span class="b-footer__contact-text">Проспект  Степана Бандери , буд. 22-а</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--  -->

    <!-- popup -->
    <div class="b-popup">
      <div class="b-popup__container">
        <div class="b-popup__wrap">
          <div class="b-popup__images">
            <a href="https://mazda.ua/" target="_blank" class="b-popup__image">
              <img src="img/mazda.jpg" alt="">
            </a>
            <a href="https://suzuki.ua/" target="_blank" class="b-popup__image">
              <img src="img/suzuki.jpg" alt="">
            </a>
          </div>
          <div class="b-popup__content">
            <div class="b-popup__text text-uppercase">ДОЧІРНЄ ПІДПРИЄМСТВО "АВТО ІНТЕРНЕШНЛ",<br>
              ЯКЕ ПОВНІСТЮ НАЛЕЖИТЬ ІНОЗЕМНОМУ ІНВЕСТОРУ<br>
              (ДП "АВТО ІНТЕРНЕШНЛ")</div>
            <div class="b-popup__address">
              Україна,  04073, м. Київ,<br>
              Проспект  Степана Бандери , буд. 22-а</div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  name: "Mazda",
  data() {
    return {
      session: "",
      expiration: 0,
      loading: true,
      error: false,
      files: [],
      years: [],
      year: 0,
      filterYear: 2021,
      displayFiles: [],
      open: false,
    };
  },
  methods: {
    openMenu() {
      let popup = document.querySelector('.b-popup');
      this.open = !this.open;
      popup.classList.toggle('open');
      document.body.classList.toggle('is-scroll-disabled');
    },
    filterYearChange() {
      if (this.filterYear === 0) {
        this.displayFiles = [];
        for (let i = 0; i < this.files.length; i++) {
          this.displayFiles.push(this.files[i]);
        }
      } else {
        this.displayFiles = [];
        for (let i = 0; i < this.files.length; i++) {
          if (parseInt(this.files[i].Year) === parseInt(this.filterYear)) {
            this.displayFiles.push(this.files[i]);
          }
        }
      }
    },
  },
  mounted() {
    var xhr = new XMLHttpRequest();
    var sendThis = this;

    var xhrfiles = new XMLHttpRequest();
    xhrfiles.onreadystatechange = () => {
      if (xhrfiles.readyState == 4) {
        if (xhrfiles.status == 200) {
          let data = JSON.parse(xhrfiles.responseText);
          sendThis.files = data.data;
          for (let i = 0; i < data.data.length; i++) {
            if (!sendThis.years.includes(data.data[i].Year)) {
              sendThis.years.push(data.data[i].Year);
            }
            data.data[i].downloadlink =
              "https://www.sc-ai.org.ua/api/v1/getfilewithsession/" +
              sendThis.session +
              "/" +
              data.data[i].ID;
          }

          sendThis.years.sort();
          if (sendThis.years.length > 0) {
            sendThis.filterYear = sendThis.years[sendThis.years.length-1];
          } else {
            sendThis.filterYear = 0;
          }
          /*
          for (let i = 0; i < sendThis.years.length; i++) {
            let x = {
              value: sendThis.years[i],
              text: sendThis.years[i],
            };
            sendThis.options.push(x);
          }
          */
          for (let i = 0; i < data.data.length; i++) {
            sendThis.displayFiles.push(data.data[i]);
          }

          if (this.filterYear === 0) {
            this.displayFiles = [];
            for (let i = 0; i < this.files.length; i++) {
              this.displayFiles.push(this.files[i]);
            }
          } else {
            this.displayFiles = [];
            for (let i = 0; i < this.files.length; i++) {
              if (parseInt(this.files[i].Year) === parseInt(this.filterYear)) {
                this.displayFiles.push(this.files[i]);
              }
            }
          }

          sendThis.error = false;
          sendThis.loading = false;
          //console.log(sendThis.files);
          //console.log(sendThis.years);
        } else {
          sendThis.error = true;
          sendThis.loading = false;
          console.log("Error code:", xhrfiles.status, xhrfiles.statusText);
        }
      }
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          let data = JSON.parse(xhr.responseText);
          sendThis.session = data.session;
          sendThis.expiration = data.expiration;
          let sendData = {
            year: 0,
            session: sendThis.session,
          };
          let json = JSON.stringify(sendData);
          xhrfiles.open("POST", "https://www.sc-ai.org.ua/api/v1/files/list", true);
          xhrfiles.setRequestHeader("Content-type", "application/json");
          xhrfiles.send(json);
        } else {
          sendThis.error = true;
          sendThis.loading = false;
          console.log("Error code:", xhr.status, xhr.statusText);
        }
      }
    };
    xhr.open("POST", "https://www.sc-ai.org.ua/api/v1/session/start", true);
    xhr.send();
  },
};
</script>
